.ol-attribution.ol-uncollapsible.ol-control {
    font-size: 12px;
    background-color: rgba(136, 154, 140, 0.11);
}
/**********  Zoom buttons **********/
.ol-control.zoom-container {
    right: 0;
    top: 0;
    display: flex;
    background-color: transparent;
}
.ol-control.zoom-container button {
    height: 36px;
    width: 36px;
    margin-top: 12px;
    margin-right: 12px;
    cursor: pointer;
}
.ol-control.zoom-container button:hover,
.ol-control.zoom-container button:focus {
    outline: none;
}
.ol-control.zoom-container button img {
    height: 36px;
    width: 36px;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}

#location-coordinates {
    color: blue;
    font-size: 14px;
    max-height: 118px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: #ddd6cebd;
    max-width: 400px;
    padding: 4px;
}
