@import 'fonts/Roboto/stylesheet.css';

body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto' 'Oxygen',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  width: 100%;
  max-width: 1482px;
  padding: 0 20px;
  margin: 0 auto;
}

#mobile-menu-drawer {
  display: none;
}

@media only screen and (max-width: 1200px) {
  #mobile-menu-drawer {
    display: block;
  }

  .mobile-menu-root .ant-drawer-content-wrapper {
    padding-bottom: 0 !important;
  }
}

@media only screen and (max-width: 1200px) {
  .farm-menu-root .farm-members {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .farm-menu-root .farm-members img {
    width: 24px;
    height: 21px;
  }
}
